import axios from '@axios'

export default {
  state: {
    commonCodeList: [],
  },
  getters: {
    getCommonCodeList: state => payload => state.commonCodeList[payload],
  },
  mutations: {
    setCommonCodeList(state, payload) {
      state.commonCodeList[payload.code] = payload.data
    },
  },
  actions: {
    async getCode({ commit, getters }, payload) {
      const pageNum = payload.start ? payload.start : 0
      const rowNum = payload.limit ? payload.limit : 10
      const code = payload.code ? payload.code : payload
      const searchType = 'code'
      const url = `/commoncode/detail?${searchType}=${code}&pageNum=${pageNum}&rowNum=${rowNum}`
      return await axios.get(url)
        .then(res => {
          commit('setCommonCodeList', { code: payload, data: res.data })
          const codeList = getters.getCommonCodeList(payload)
          const { data } = codeList
          const newOptions = []
          for (let i in data) {
            newOptions.push({
              label: $t(`label.${data[i].detailCodeName}`), // 콤보박스에서 보여지는 값
              code: data[i].detailCode, // 실제 값
            })
          }
          return newOptions
        })
    },
  },
}
