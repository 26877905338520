import Vue from "vue";
import VueRouter from "vue-router";
import api from '@/libs/api.js'
import EventBus from '@/event-bus'
// Routes
import { canNavigate } from "@/libs/acl/routeProtection";
import {
  isUserLoggedIn,
  getUserData,
  getHomeRouteForLoggedInUser,
} from "@/auth/utils";
import metabuild from "./routes/metabuild";
import useJwt from "@/auth/jwt/useJwt";
import store from "@/store";

//추가

import config from '../../config';
import jwt from '../libs/jwt'
import storage from '../libs/globalStorage';

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    // { path: "/", redirect: { name: "main" } }, // hwy 개발 완료시 변경해야 함 1-login 또는 웰컴 페이지로...dashboard-ecommerce
    ...metabuild,
    {
      path: "*",
      redirect: { name: "error-404" },
    },
  ],
});

const originalPush = router.push;
router.push = function push(location) {
  return originalPush.call(this, location).catch(err => {
    if (err.name !== 'NavigationDuplicated') throw err;
  });
}

router.beforeEach((to, from, next) => {
  if (localStorage.getItem('userData') && to.name == 'auth-login') {
    next({ name: 'event' })
    // api.getJson()
    // .then((res) => {
    //     return res.data.data;
    // }).then(json => {
    //     for(var key in json) {
    //         storage.set(key,json[key]);
    //     }
    //     EventBus.$emit('storage',storage.data);
    // })
  } else {

    //  next({name: 'auth-login'})
  }

  if (to.name == null) {
    next({ name: to.hash.split('/')[1] });
  }

  if (to.name == null && from.name == null) {
    next({ name: to.hash.split('/')[1] });
  }
  //  return next({name:'event'})
  // next({name:'event'})
  // next('event');
  // hwy login process deleted [START]
  // 권한이 없거나 존재하지 않는 페이지면 에러 페이지로 라우팅
  // if (to.name == 'error-404')
  //   return next();

  // const isLoggedIn = isUserLoggedIn();

  // if (!canNavigate(to)) {
  //   // Redirect to login if not logged in
  //   if (!isLoggedIn) return next({ name: "auth-login" });

  //   // If logged in => not authorized
  //   return next({ name: "error-404" });
  // }

  // // Redirect if logged in
  // if (to.meta.redirectIfLoggedIn && isLoggedIn) {
  //   //const userData = getUserData();

  //   next(getHomeRouteForLoggedInUser(userData ? userData.role : null));
  // }

  // if (isLoggedIn && to.name != 'main') {
  //   const menuString = JSON.stringify(JSON.parse(localStorage.getItem('userData')).menu);
  //   // 종합 분석 시스템 라우팅을 위한 예외 처리
  //   if (menuString.indexOf(to.fullPath.startsWith('/admin/analysis/') ? 'AnalysisBaseChoice' : to.name) < 0)
  //     return next({ name: "error-404" });
  // }
  if (process.env.NODE_ENV == 'development' && config.dev.nologin) {
    next();
    return;
  }
  if (to.name == 'dashboardReplay' && from.name == null) {
    next('replay');
  }
  // &&localStorage.getItem('userData')
  if (to.name != 'auth-login') {
    jwt.validToken(() => {
      var auth = storage.get("auth");
      checkAuth(to, from, next, auth);
    }, async () => {
      if (localStorage.getItem('userData')) {
        // store.commit("verticalMenu/INIT_MENU");
        // store.commit("verticalMenu/INIT_USER_DATA");
        store.commit("verticalMenu/INIT_ALL_DATA");
        Vue.$cookies.remove("hasToken");
        localStorage.removeItem('userData');
        alert("로그인 세션이 만료되었습니다");
      }

      else {

        next({ name: 'auth-login' });
      }

      next({ name: 'auth-login' });

    })
  }
  else {
    // next();
  }

  return next();
  //hwy login process deleted [END]
  // return next();
});
const checkAuth = function (to, from, next, auth) {
  if (to.meta.auth) {
    if (to.meta.auth.find(a => a == auth)) {
      next();
    }
  } else {
    next();
  }
}
// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach((a, b, c) => {

  // if(a.name=='main'&&a.fullPath!='/'){
  //   router.push({name:a.fullPath.replace('/#/','')})
  // }
  // Remove initial loading
  const appLoading = document.getElementById("loading-bg");
  if (appLoading) {
    appLoading.style.display = "none";
  }
});

export default router;
