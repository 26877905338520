
export default {
  namespaced: true,
  state: {
    jsonFile:null
  },
  getters: {
    getTarget:  (state) => (id) => {
      return state[id]
    }
    //this.$store.getters['settingConfig/getTarget'](id)
  },
  mutations: {
       setJsonFile(state, val) {
          for(var key in val) {
                    state[key] = val[key]
              }
        },
  },
  actions: {},
}
