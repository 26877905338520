import Vue from 'vue'
export default [
  {
    path: "/#/login",
    name: "auth-login",
    component: () => import("@/views/metabuild/login/Login.vue"), // hwy
    meta: {
      layout: "full",
      resource: "Auth",
      redirectIfLoggedIn: true,
    },
  },
  {
    path: "/#/selectProject",
    name: "selectProject",
    component: () => import("@/views/metabuild/login/selectProject.vue"), 
    meta: {
      // pageTitle: "프로젝트 선택",
      // i18nCode: "label.L0015"
    },
  },
  {
    // path: "/#/",
    path: "/",
    name: "main",
    beforeEnter(to, from, next) {
      if (localStorage.getItem("userData") != null) {
        next({ name: "dashboard" });
        // next({ name: "equipment" });
        // next({ name: "event" });
        // next({ name: "selectProject" });
      } else {
        next({ name: "auth-login" });
        // next();
      }
    },
  },
  {
    path: "/#/dashboard",
    name: "dashboard",
    component: () => import("@/views/metabuild/dashboard/Dashboard.vue"),
    meta: {
      layout: "full",
    },
  },
  {
    path: "/#/equipment",
    name: "equipment",
    component: () => import("@/views/metabuild/equipment/equip.vue"),
    meta: {
      pageTitle: "장비 관리",
      i18nCode: "label.L0003"
    },
  },
  {
    path: "/#/event",
    name: "event",
    component: () => import("@/views/metabuild/event/event.vue"),
    meta: {
      pageTitle: "돌발 이력 관리",
      i18nCode: "label.L0007"
    },
  },
  {
    path: "/#/user",
    name: "user",
    component: () => import("@/views/metabuild/user/user.vue"),
    meta: {
      pageTitle: "사용자 관리",
      i18nCode: "label.L0005"
    },
  },
  {
    path: "/#/schedule",
    name: "schedule",
    component: () => import("@/views/metabuild/schedule/schedule.vue"),
    meta: {
      pageTitle: "공사일정 관리",
      i18nCode: "label.L0004"
    },
  },
  {
    path: "/#/statistics",
    name: "statistics",
    component: () => import("@/views/metabuild/statistics/statistics.vue"),
    meta: {
      pageTitle: "통계 이력",
      i18nCode: "label.L0006"
    },
  },
  // {
  //   path: "/#/replay",
  //   name: "replay",
  //   component: () =>
  //     import(
  //       "@/views/metabuild/replay/replay.vue"
  //     ),
  // },

  {
    path: "/#/admin/error",
    name: "error-404",
    component: () => import("@/views/metabuild/error.vue"),
    meta: {
      layout: "full",
    },
  },
];
