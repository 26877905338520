import Vue from "vue";
import { ToastPlugin, ModalPlugin } from "bootstrap-vue";
import VueCompositionAPI from "@vue/composition-api";
import Dashboard from './libs/dashboard'

import i18n from "@/libs/i18n.js";
import router from "./router";
import store from "./store";
import App from "./App.vue";
import api from "./libs/api"

// Global Components
import "./global-components";
// 3rd party plugins
import "@axios";
import "@/libs/acl";
import "@/libs/portal-vue";
import "@/libs/clipboard";
import "@/libs/toastification";
import "@/libs/sweet-alerts";
import "@/libs/vue-select";
import "@/libs/tour";
// Axios Mock Adapter
import "@/@fake-db/db";

//
// import 'froala-editor/js/plugins.pkgd.min.js';
//Import third party plugins
// import 'froala-editor/js/third_party/embedly.min';
// import 'froala-editor/js/third_party/font_awesome.min';
// import 'froala-editor/js/third_party/spell_checker.min';
// import 'froala-editor/js/third_party/image_tui.min';
// Import Froala Editor css files.
// import 'froala-editor/css/froala_editor.pkgd.min.css';

// Import and use Vue Froala lib.
// import VueFroala from 'vue-froala-wysiwyg'

// print
import VueHtmlToPaper from 'vue-html-to-paper';

// Vue.use(VueFroala)
//
// BSV Plugin Registration
Vue.use(ToastPlugin);
Vue.use(ModalPlugin);

// Composition API
Vue.use(VueCompositionAPI);

Vue.use(VueHtmlToPaper);

Vue.use(Dashboard);
// Feather font icon - For form-wizard
// * Shall remove it if not using font-icons of feather-icons - For form-wizard
require("@core/assets/fonts/feather/iconfont.css"); // For form-wizard

// import core styles
require("@core/scss/core.scss");

// import assets styles
require("@/assets/scss/style.scss");

Vue.config.productionTip = false;

// Vue.prototype.$EventBus = new Vue();
import L from 'leaflet'
import "leaflet-rotate";
// map
import { Icon } from "leaflet";

delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

String.prototype.toCamelCase = function() {
    return this.toLowerCase().replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase());
}

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");

